import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Modal, ModalBody,
  Card, CardBody,
  Col, Row, Table,
  UncontrolledTooltip,
  Container
} from "reactstrap";

class TeamListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null
    };
  }

  hideAlert = () => {
    this.setState({alert: null});
  }
  hideModal = () => {
    this.hideAlert();
    this.props.toggle();
  }
  handleLeaveTeam = (teamID, teamName) => {
    if(this.props.leaveTeamFunc(teamID, teamName)) {
      this.hideModal();
    }
  }
  handleJoinTeam = () => {
    this.hideModal();
    this.props.toggleJoinTeam();
  }

  leaveTeamWarning = (teamID, teamName) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={"Are you sure you want to leave team "+teamName+"?"}
          onConfirm={() => this.handleLeaveTeam(teamID, teamName)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, leave team!"
          cancelBtnText="Cancel"
          className="modal-black"
          showCancel
          btnSize=""
        >
          <p>You will need to contact the team owner to be invited back.</p>
        </ReactBSAlert>
      )
    });
  };

  /* Generate team rows */
  createTeamRows = (teams) => {
    var teamrows = teams.map(
      function iterator( team ) {
        return(
          <tr key={team.teamID}>
            <td className="text-center">
              <div className="photo">
                <img
                  alt="..."
                  src={require("assets/img/etiqit-logo.png")}
                />
              </div>
            </td>
            <td>{team.teamName}</td>
            <td>{team.publicEmail}</td>
            <td className="text-right">
              {
                // edit team
                this.props.userData.userID === team.userID &&
                  <>
                    <Button
                      className="btn-link table-action"
                      color="info"
                      id={team.teamID + 'dt'}
                      size="sm"
                    >
                      <i className="fas fa-cog" /> Edit
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target={team.teamID + 'dt'}
                    >
                      {"Edit "+team.teamName}
                    </UncontrolledTooltip>
                  </>
              }
              {
                // leave team
              }
              <Button
                className="btn-link table-action"
                color="danger"
                id={team.teamID + 'lt'}
                size="sm"
                onClick={() => this.leaveTeamWarning(team.teamID, team.teamName)}
              >
                <i className="fas fa-sign-out-alt" /> Leave
              </Button>
              <UncontrolledTooltip
                delay={0}
                target={team.teamID + 'lt'}
              >
                {"Leave "+team.teamName}
              </UncontrolledTooltip>
            </td>
          </tr>
        );
      },
      this
    );
    teamrows.push(
      <tr key="jointeam-row" className="jointeam-row">
        <td className="text-center" colSpan="4">
          <div onClick={() => this.handleJoinTeam()} className="jointeam-link">
            <i className="fas fa-plus-square" /> Join or Create Team
          </div>
        </td>
      </tr>
    );
    return teamrows
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.hideModal}
        className="modal-long"
        modalClassName="modal-black"
        size="lg"
      >
        {this.state.alert}
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.props.toggle}
          >
            <i className="fas fa-times" />
          </button>
          <h6 className="title title-up">My Teams</h6>
        </div>
        <ModalBody className="">
          <Container>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">Image</th>
                          <th>Team Name</th>
                          <th>Public Email</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.createTeamRows(this.props.userData.teams)}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <div className="modal-footer">
        </div>
      </Modal>
    );
  }
}

export default TeamListModal;
