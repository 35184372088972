import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Amplify from "aws-amplify";

import config from "./config";

import SiteLayout from "layouts/Site/Site.jsx";
import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import AppLayout from "layouts/App/App.jsx";

import "assets/scss/etiqit.scss?v=1.0.0";
import "react-notification-alert/dist/animate.css";

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Baloo Chettan:400', 'cursive']
  }
});

const hist = createBrowserHistory();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "etiqit",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "etiqitNotifications",
        endpoint: config.wsapiGateway.URL,
        region: config.wsapiGateway.REGION
      },
    ]
  }
});

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/app" render={props => <AppLayout {...props} />} />
      <Route exact path="/" render={props => <SiteLayout {...props} />} />
      <Redirect from="/" to="/app/home" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
