var api_url = "https://api-stage.etiq.it";
var wsapi_url = "wss://ws-stage.etiq.it";
if (process.env.REACT_APP_ENV === "production") {
  api_url = "https://api.etiq.it";
  wsapi_url = "wss://ws.etiq.it";
}

export default {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: api_url
  },
  wsapiGateway: {
    REGION: "us-east-1",
    URL: wsapi_url
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_yQQucFGTP",
    APP_CLIENT_ID: "253pd43io3jpvp98fj3hgil3c5"
  }
};
