/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {
  Nav,
  Collapse,
  UncontrolledTooltip,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusGroup: "open",
      focusTeam: this.props.userData.teams[0].teamID
    }
  }


  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = teamID => {
    return this.props.ticketGroups.map((ticketGroup) => {
        return (
          <li className={this.activeGroup(teamID, ticketGroup.id)} key={teamID+ticketGroup.id}>
            <NavLink to="#" activeClassName="" onClick={() => this.updateActiveGroup(teamID, ticketGroup.id)}>
              <i className={ticketGroup.icon} />
              <p>
                {ticketGroup.name}
                <span className="pull-right badge badge-info group-badge">
                  {this.props.tickets[teamID][ticketGroup.id].length}
                </span>
              </p>
            </NavLink>
          </li>
        );
      }
    )
  }
  // verifies if groupName is the one active
  activeGroup = (teamID, groupID) => {
    return (this.state.focusGroup == groupID && this.state.focusTeam == teamID) ? "active" : "";
  };

  updateActiveGroup = (teamID, groupID) => {
    event.preventDefault();

    this.setState({ focusGroup: groupID});
    this.setState({ focusTeam: teamID});

    this.props.updateGroup(teamID, groupID);
  }

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar);
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { activeColor, logo } = this.props;
    let logoImg = null;
    if (logo !== undefined) {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={this.props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="team-icon" />
          </div>
        </NavLink>
      );
    }
    return (
      <div className="sidebar" data={activeColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          {this.props.userData.teams.map(
            function iterator( team ) {
              return(
                <section className="logo team" key={team.teamID}>
                  {logoImg}
                  <NavLink
                    to={logo.innerLink}
                    className="simple-text logo-normal team-header"
                    onClick={this.props.closeSidebar}
                    id={team.teamID}
                  >
                    {team.teamName}
                    <UncontrolledDropdown nav className="pull-right">
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        nav
                        tag="i"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fas fa-cog" />
                        <b className="caret d-none d-lg-block d-xl-block" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-team-cog dropdown-black" right tag="ul">
                        <li className="nav-header">{team.publicEmail}</li>
                        <DropdownItem className="nav-item"><i className="fas fa-user-plus" />Invite User</DropdownItem>
                        <DropdownItem className="nav-item"><i className="fas fa-user-cog" />Manage Users</DropdownItem>
                        <DropdownItem className="nav-item"><i className="fas fa-cog" />Settings</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavLink>
                  <Nav>{this.createLinks(team.teamID)}</Nav>
                </section>
              );
            },
            this
          )}
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func
};

export default Sidebar;
