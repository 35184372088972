import React from "react";

// reactstrap components
import {
  Button,
  Modal, ModalBody,
  Card, CardBody, CardText, CardFooter,
  Col, Row,
  Container
} from "reactstrap";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

class UserProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null
    };
  }

  hideAlert = () => {
    this.setState({alert: null});
  }
  hideModal = () => {
    this.hideAlert();
    this.props.toggle();
  }

  render() {
    //console.log(this.props.userData);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.hideModal}
        className="modal-long"
        modalClassName="modal-black"
        size="lg"
      >
        {this.state.alert}
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.props.toggle}
          >
            <i className="fas fa-times" />
          </button>
          <h6 className="title title-up">My Profile</h6>
        </div>
        <ModalBody className="">
          <Container>
            <Row>
              <Col>
                <Card className="card-user">
                  <CardBody>
                    <CardText />
                    <div className="author">
                      <div className="block block-one" />
                      <div className="block block-two" />
                      <div className="block block-three" />
                      <div className="block block-four" />
                      <ImageUpload
                        avatar
                        addBtnColor="default"
                        changeBtnColor="default"
                      />
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <h5 className="title">{this.props.userData.full_name}</h5>
                      </a>
                      <p className="description">{this.props.userData.email}</p>
                    </div>
                    <div className="card-description">
                      profile fields go here
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="button-container">
                      <Button className="btn-icon btn-round" color="facebook">
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button className="btn-icon btn-round" color="twitter">
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button className="btn-icon btn-round" color="google">
                        <i className="fab fa-google-plus" />
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

export default UserProfileModal;
