import React from "react";
import { Redirect } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import Moment from 'react-moment';
import 'moment-timezone';
import Sockette from 'sockette';

// core components
import AppNavbar from "components/Navbars/AppNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import TicketModal from "components/Modals/Ticket.jsx";
import JoinTeamModal from "components/Modals/JoinTeam.jsx";
//import Footer from "components/Footer/Footer.jsx";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import routes from "routes.js";

import loadingSVG from "assets/img/gears.svg";
import logo from "assets/img/etiqit-logo-small.png";

var ps;

const ticketGroups = [
  {id: "open", name: "open tickets", icon: "far fa-envelope", style: "primary"},
  {id: "waiting", name: "waiting reply", icon: "far fa-hourglass", style: "warning"},
  {id: "hold", name: "on hold", icon: "far fa-hand-paper", style: "danger"},
  {id: "closed", name: "closed tickets", icon: "fas fa-check", style: "success"}
];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.ticketSelected = this.ticketSelected.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.ticketReply = this.ticketReply.bind(this);
    this.leave_team = this.leave_team.bind(this);
    this.genericAlert = this.genericAlert.bind(this);

    this.state = {
      apiOptions: {},
      activeColor: "blue",
      sidebarMini: false,
      opacity: 0,
      sidebarOpened: true,
      isAuthenticated: false,
      isAuthenticating: true,
      isLoading: true,
      isThinking: false,
      userData: {},
      notification: null,
      notifications: [],
      focusTeam: "",
      focusGroup: "open",
      focusTicket: false,
      showTicketModal: false,
      focusTicketGroup: [],
      ticketSort: "timestamp_received",
      tickets: [],
      hasTeam: true,
      showJoinTeamModal: false,
      websocket: false
    };
  }
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  handleLogout = async event => {
    await Auth.signOut();

    // this.state.websocket.close(); WEBSOCKET TODO

    this.userHasAuthenticated(false);
  }
  async componentWillMount() {
    try {
      var session = await Auth.currentSession();
      this.userHasAuthenticated(true);
      this.setState({ apiOptions: { headers: { Authorization: session.idToken.jwtToken }} });

      // Get user data
      const userData = await this.user();
      if(userData.teams.length === 0) {
        this.setState({ 'hasTeam': false });
        this.setState({ 'showJoinTeamModal': true });
        this.setState({ 'tickets': []});
        this.setState({ 'focusTicketGroup': []});
        this.setState({ userData });
      } else {
        userData.teams = userData.teams.sort(this.dynamicSort('teamName'));
        this.setState({ userData });
        this.setState({ focusTeam: this.state.userData.teams[0].teamID });
        this.setState({ notifications: this.state.userData.notifications });

        // Get tickets
        const tickets = await this.tickets();
        this.setState({ tickets });
        this.updateTicketCards(this.state.focusTeam, this.state.focusGroup);
        this.sortCards();

        // Initialize websockets connection
        /* WEBSOCKET TODO
        var wsapiUri = await API.endpoint('etiqitNotifications');
        var wsConn = new Sockette(wsapiUri +"?jwt="+ session.accessToken.jwtToken, {
          timeout: 5e3,
          maxAttempts: 5,
          onopen: e => this.wsConnected(e),
          onmessage: e => this.wsMessage(e),
          onreconnect: e => console.log('Reconnecting...', e),
          onmaximum: e => console.log('Stop Attempting!', e),
          onclose: e => this.wsDisconnected(e),
          onerror: e => this.wsError(e)
        });
        this.setState({websocket: wsConn});
        */
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && this.state.isAuthenticated) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && this.state.isAuthenticated) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  /* Websocket notifications */
  wsConnected(evt) {
    console.log("Connected to Etiqit notifications.");
  }
  wsMessage(evt) {
    // handle notification event
    console.log("Got a message! "+evt.data);
  }
  wsDisconnected(evt) {
    console.log("Disconnected from Etiqit notifications.");
  }
  wsError(evt) {
    console.log("Error with websocket!",evt);
  }

  /* Sorting */
  sortCards(sortProperty = "timestamp_received") {
    this.setState({ focusTicketGroup: this.state.focusTicketGroup
                              .sort(this.dynamicSort(this.state.ticketSort)) });
  }
  dynamicSort(property) {
    var sortOrder = -1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  /* Notifications */
  hideNotification = () => {
    this.setState({ notification: null });
  }

  showNotification = (notification) => {
    this.setState({
        notification: (
            <SweetAlert
                info
                style={{display: "block"}}
                title={notification.message}
                onConfirm={() => this.hideNotification()}
                confirmBtnBsStyle="info"
                confirmBtnText="Got it!"
            >
            </SweetAlert>
        )
    });
  }

  /* API Calls */
  tickets() {
    return API.post("etiqit", "/ticket/get_all", this.state.apiOptions);
  }
  async ticketReply(content, contentText) {
    this.setState({ isThinking: true });
    var payload = this.state.apiOptions;
    payload.body = {
      ticketID: this.state.focusTicket.ticketID,
      reply: content,
      reply_text: contentText
    };

    return await API.post("etiqit", "/ticket/reply", payload).then(res => {
      if(res.success) {
        this.state.focusTicket.threads.push(
          {
            attachments: [],
            body: content,
            date: Math.round((new Date()).getTime()),
            from: {
              address: this.state.userData.email,
              name: this.state.userData.full_name
            },
            to: {
              address: res.data
            }
          }
        );
        this.state.focusTicket.threads.sort(this.dynamicSort("date"));

        this.setState({ isThinking: false });
        let options = {};
        options = {
          place: "tr",
          message: res.message,
          type: "success",
          icon: "far fa-thumbs-up",
          autoDismiss: 5
        };
        this.refs.notificationAlert.notificationAlert(options);
        return true;
      } else {
        let options = {};
        options = {
          place: "tr",
          message: res.message,
          type: "danger",
          icon: "far fa-frown",
          autoDismiss: 5
        };
        this.refs.notificationAlert.notificationAlert(options);
        return false;
      }
    });
  }
  async get_ticket(ticketID) {
    var postData = this.state.apiOptions;
    postData.body = {'ticketID': ticketID};
    return await API.post("etiqit", "/ticket/get", postData).then(res => {
      res.threads.sort(this.dynamicSort("date"));
      this.setState({ isThinking: false });
      return res;
    });
  }
  user() {
    return API.post("etiqit", "/user/get", this.state.apiOptions);
  }
  async leave_team(teamID, teamName) {
    var postData = this.state.apiOptions;
    postData.body = {'teamID': teamID};
    return await API.post("etiqit", "/user/leaveteam", postData).then(res => {
      this.setState({ isThinking: false });
      this.componentWillMount();
      this.genericAlert("You have succesfully left team "+teamName);
      return true;
    }).catch(err => {
      console.log(err);
      this.genericAlert(err.response.data.error, false);
      return false;
    });
  }

  /* Generic Alert */
  genericAlert(message, success = true) {
    let options = {};
    options = {
      place: "tr",
      autoDismiss: 5,
      type: "success",
      message: message,
      icon: "fas fa-check-circle"
    };
    if(!success){
      options.type = "danger";
      options.icon = "fas fa-exclamation-circle";
    }
    this.refs.notificationAlert.notificationAlert(options);
  }

  /* Generate ticket cards */
  createTicketCards = () => {
    var ticket_cards = this.state.focusTicketGroup.map(
      function iterator( ticket ) {
        var ticketIDSanitized = ticket.ticketID.replace("#","");
        return(
          <Col xl="3" lg="4" md="6" key={ticketIDSanitized}>
            <Card className="ticket-card"
                  id={ticketIDSanitized}
                  onClick={() => this.ticketSelected(ticket.ticketID)}>
              <CardBody>
                <Row>
                  <Col xs="3">
                    <div className="info-icon text-center icon-warning">
                      <i className="fas fa-ticket-alt" />
                    </div>
                  </Col>
                  <Col xs="9">
                    <div className="ticket-info">
                      <p className="ticket-from">{ticket.from.name}</p>
                      <CardTitle tag="h3">{ticket.title}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats" id={ticketIDSanitized + "_footer"}>
                  <i className="far fa-clock" />
                  Last reply <Moment fromNow>{ticket.timestamp_received}</Moment>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target={ticketIDSanitized + "_footer"}
                  placement="bottom"
                >
                  <Moment format="MMMM Do, YYYY h:mma">{ticket.timestamp_received}</Moment>
                </UncontrolledTooltip>
              </CardFooter>
            </Card>
          </Col>
        );
      },
      this
    );

    return ticket_cards
  }

  updateGroup = (teamID, ticketGroupID) => {
    this.setState({ focusGroup: ticketGroupID});
    this.setState({ focusTeam: teamID});

    this.updateTicketCards(teamID, ticketGroupID);
  }

  updateTicketCards = (teamID, ticketGroupID) => {
    this.setState({ focusTicketGroup: this.state.tickets[teamID][ticketGroupID] });
  }

  async ticketSelected(ticketID) {
    this.setState({ isThinking: true });
    this.setState({ focusTicket: await this.get_ticket(ticketID) });
    this.setState({ showTicketModal: true });
  }

  closeTicket = () => {
    this.setState({ showTicketModal: false });
    this.setState({ focusTicket: false });
  }

  toggleJoinTeam = () => {
    this.setState({ showJoinTeamModal: !this.state.showJoinTeamModal });
  }

  async removeNotification(notification) {
    var filtered_notifications = this.state.notifications.filter(function(n){
      return n.id !== notification.id;
    });
    // remove notification from frontend
    this.setState({ notifications: filtered_notifications});

    // remove notification in database
    var postData = this.state.apiOptions;
    postData.body = {'notifications': filtered_notifications};
    return await API.post("etiqit", "/user/update_notifications", postData).then(res => {
      this.setState({ isThinking: false });
      return res;
    });
  }

  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };

  getAuth = () => {
    if (this.state.isAuthenticated) {
      return (
        <div className="content">
          <Row>
            {this.createTicketCards()}
          </Row>
        </div>
      );
    } else {
      return (
        <Redirect to='/auth/login' />
      );
    }
  };

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };

  handleMiniClick = () => {
    //let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      //notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      //notifyMessage += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
    document.documentElement.classList.toggle("nav-open");
  };

  closeSidebar = () => {
    this.setState({
      sidebarOpened: false
    });
    document.documentElement.classList.remove("nav-open");
  };

  mainPanel = () => {
    return (
      <div
        className="main-panel"
        ref="mainPanel"
        data={this.state.activeColor}
      >
        <AppNavbar
          {...this.props}
          handleMiniClick={this.handleMiniClick}
          brandText="etiqit"
          sidebarOpened={this.state.sidebarOpened}
          toggleSidebar={this.toggleSidebar}
          logoutFunc={this.handleLogout}
          userData={this.state.userData}
          notifications={this.state.notifications}
          leaveTeamFunc={this.leave_team}
          toggleJoinTeam={this.toggleJoinTeam}
          mustJoinTeam={this.state.hasTeam}
          removeNotification={this.removeNotification}
          ticketSelected={this.ticketSelected}
          showNotification={this.showNotification}
        />
        {this.getAuth()}
        {
        // we don't want the Footer to be rendered on full screen maps page
        //this.props.location.pathname.indexOf("full-screen-map") !==
        //-1 ? null : (
        //  <Footer fluid />
        //)
        }
        <TicketModal
          ticket={this.state.focusTicket}
          closeTicket={this.closeTicket}
          userData={this.state.userData}
          showTicketModal={this.state.showTicketModal}
          ticketGroups={ticketGroups}
          ticketReply={this.ticketReply}
        />
        <JoinTeamModal
          showJoinTeamModal={this.state.showJoinTeamModal}
          genericAlert={this.genericAlert}
          toggleJoinTeam={this.toggleJoinTeam}
        />
        {this.state.notification}
      </div>
    )
  }
  render() {
    if(this.state.isLoading || this.state.isAuthenticating) {
      return (
        <div className="wrapper">
          <div id="loading" className={this.state.isLoading ? "show" : ""}>
            <div className="contents">
              <img src={loadingSVG} alt="loading"/><p id="loadingText"></p>
            </div>
          </div>
        </div>
      );
    }
    if(!this.state.hasTeam) {
      return (
        <div className="wrapper">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          {this.mainPanel(this.state.jointeam)}
        </div>
      );
    }

    return (
      !this.state.isAuthenticating &&
      <div className="wrapper">
        <div id="loading" className={this.state.isThinking ? "show" : ""}>
          <div className="contents">
            <img src={loadingSVG} alt="loading"/><p id="loadingText"></p>
          </div>
        </div>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="fas fa-bars visible-on-sidebar-regular text-muted" />
            <i className="fas fa-ellipsis-v visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          logo={{
            innerLink: "/app/home",
            text: "my team",
            imgSrc: logo
          }}
          closeSidebar={this.closeSidebar}
          userData={this.state.userData}
          tickets={this.state.tickets}
          updateGroup={this.updateGroup}
          ticketGroups={ticketGroups}
        />
        {this.mainPanel()}
      </div>
    );
  }
}

export default App;
