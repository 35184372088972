import React from "react";
import ReactSVG from 'react-svg'
// nodejs library that concatenates classes
import classNames from "classnames";

// modals
import TeamListModal from "components/Modals/TeamList.jsx";
import UserProfileModal from "components/Modals/UserProfile.jsx";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

import etiqitSVG from "assets/img/etiqit.svg";

const notificationIconMap = {
  "notice": "fas fa-bell",
  "ticket": "fas fa-ticket-alt",
  "invite": "fas fa-user-astronaut"
}

class AppNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.openNotification = this.openNotification.bind(this);
    this.renderNotifications = this.renderNotifications.bind(this);

    this.state = {
      collapseOpen: false,
      modalSearch: false,
      showTeamListModal: false,
      showUserProfileModal: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  // user team list modal
  toggleTeamListModal = () => {
    this.setState({showTeamListModal: !this.state.showTeamListModal});
  };
  // user team list modal
  toggleUserProfileModal = () => {
    this.setState({showUserProfileModal: !this.state.showUserProfileModal});
  };

  // display notifications
  renderNotifications = () => {
    if (this.props.notifications.length === 0) {
      return(
        <NavLink tag="li">
          <DropdownItem className="nav-item">
            No notifications!
          </DropdownItem>
        </NavLink>
      );
    }
    var notifications = this.props.notifications.map(
        (notification) => {
          return(
            <NavLink tag="li" key={notification.date} onClick={() => this.openNotification(notification)}>
              <DropdownItem className="nav-item">
                 <i className={notificationIconMap[notification.type]} /> {notification.message}
              </DropdownItem>
            </NavLink>
          );
        });

    return notifications;
  }

  openNotification = (notification) => {
    switch(notification.type){
      case "ticket":
        this.props.ticketSelected(notification['ticket-id']);
        this.props.removeNotification(notification);
        break;
      case "invite":
        break;
      case "notice":
      default:
        this.props.showNotification(notification);
        this.props.removeNotification(notification);
        break;
    }
  }

  render() {
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <ReactSVG src={etiqitSVG} className="etiqit-logo" />
                </Button>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <InputGroup className="search-bar" tag="li">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="fas fa-search" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup>
                <UncontrolledDropdown nav className="notification-wrapper">
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className={(this.props.notifications.length > 0 ? "notification": "") + " d-none d-lg-block d-xl-block"} />
                    <i className="fas fa-bell" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar dropdown-black" right tag="ul">
                    {this.renderNotifications()}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src={require("assets/img/avatar.png")} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">{this.props.userData.full_name}</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar dropdown-black" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.toggleUserProfileModal()}><i className="far fa-address-card" />My Profile</DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.toggleTeamListModal()}><i className="fas fa-users" />My Teams</DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item"><i className="fas fa-cog" />Settings</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={this.props.logoutFunc}><i className="fas fa-door-open" />Log out</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <TeamListModal
          isOpen={this.state.showTeamListModal}
          toggle={this.toggleTeamListModal}
          userData={this.props.userData}
          leaveTeamFunc={this.props.leaveTeamFunc}
          toggleJoinTeam={this.props.toggleJoinTeam}
        />
        <UserProfileModal
          isOpen={this.state.showUserProfileModal}
          toggle={this.toggleUserProfileModal}
          userData={this.props.userData}
        />
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AppNavbar;
