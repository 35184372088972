import React from "react";

// reactstrap components
import {
  Button,
  Modal, ModalBody,
  Card, CardBody, CardFooter, CardTitle, CardImg,
  ListGroup, ListGroupItem,
  Col, Row,
  Container,
  Input
} from "reactstrap";

class JoinTeamModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  closeJoinTeamModal = () => {
    if(!this.props.mustJoinTeam) {
      this.props.toggleJoinTeam();
    } else {
      this.props.genericAlert("You must join a team to use Etiqit!", false);
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showJoinTeamModal}
        toggle={!this.props.showJoinTeamModal ? this.closeJoinTeamModal : null}
        className="modal-long"
        modalClassName="modal-black"
        size="lg"
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.closeJoinTeamModal}
          >
            <i className="fas fa-times" />
          </button>
          <h6 className="title title-up">Join or Create a Team</h6>
        </div>
        <ModalBody className="">
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Card className="card-jointeam card-primary">
                  <CardBody>
                    <CardTitle tag="h1">join</CardTitle>
                    <CardImg
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <ListGroup>
                      <ListGroupItem>Join an existing team with invite code.</ListGroupItem>
                    </ListGroup>
                    <Col sm="9" className="ml-auto mr-auto">
                      <div className="small">
                        <p className="plan">Enter invite code:</p>
                      </div>
                      <Input
                        type="text"
                        name="teamJoinCode"
                        id="teamJoinCode"
                        defaultValue=""
                        placeholder="Invite Code" />
                    </Col>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="primary">
                      Join
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col sm="12" lg="6">
                <Card className="card-jointeam card-primary">
                  <CardBody>
                    <CardTitle tag="h1">create</CardTitle>
                    <CardImg
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <ListGroup>
                      <ListGroupItem>Purchase a new team and invite your own users.</ListGroupItem>
                    </ListGroup>
                    <div className="card-prices">
                      <h3 className="text-on-front">
                        <span>$</span>
                        15
                      </h3>
                      <h5 className="text-on-back">15</h5>
                      <p className="plan">Plans start at just $15/month</p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="primary">
                      Choose Plan
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <div className="modal-footer">
        </div>
      </Modal>
    );
  }
}

export default JoinTeamModal;
