import React from "react";
import sanitizeHtml from "sanitize-html";
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'moment-timezone';

// reactstrap components
import {
  Button,
  Modal, ModalBody,
  Card, CardBody, CardFooter,
  Badge,
  Col, Row,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledTooltip
} from "reactstrap";

const contentTypes = {
  "jpeg": "far fa-image",
  "jpg": "far fa-image",
  "png": "far fa-image",
  "gif": "far fa-image",
};

class TicketModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleReplyClick = this.handleReplyClick.bind(this);

    this.state = {
      editorContentHTML: "",
      editorContentText: "",
      editorState: EditorState.createEmpty()
    };
  }

  onEditorStateChange = (editorState) => {
    // store the html content of the reply in the state
    this.setState({
      editorContentHTML: stateToHTML(editorState.getCurrentContent())
    });
    // store the plain text content of the reply in the state
    this.setState({
      editorContentText: editorState.getCurrentContent().getPlainText()
    });
    this.setState({
      editorState
    });
  }

  uploadImageCallBack = (file) => {
    return;
  }

  getReplyIcon = (reply) => {
    if (this.replyIsSelf(reply.from.address)) {
      return (
        <div className="ticket-photo">
          <img alt="..." src={require("assets/img/avatar.png")} />
        </div>
      )
    } else {
      return (
        <i className="far fa-comments" />
      )
    }
  }

  getAttachments = (attachments) => {
    if (attachments && attachments.length > 0) {
      return attachments.map(
        function iterator( att ) {
          var contentType = att.contentType.split("/")[1];
          return(
            <i key={att.filename} className={contentTypes[contentType]} />
          )
        }
      )
    }
    return;
  }

  replyIsSelf = (address) => {
    if (address === this.props.userData.email) {
      return true;
    }
    return false;
  }

  closeTicketModal = () => {
    this.setState({ showModal: false });
    this.setState({ editorState: EditorState.createEmpty() });
    this.props.closeTicket();
  }

  // when reply button is clicked
  async handleReplyClick() {
    if(await this.props.ticketReply(this.state.editorContentHTML, this.state.editorContentText)) {
      this.setState({
        editorState: EditorState.createEmpty()
      });
    }
  }

  createReplies = () => {
    if (this.props.ticket === false) return;
    var replies = this.props.ticket.threads.map(
        function iterator( reply ) {
          return(
            <li className="timeline-inverted" key={reply.date}>
              <div className="timeline-badge info">
                {this.getReplyIcon(reply)}
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color={this.replyIsSelf(reply.from.address) ? "danger" : "info"}>{reply.from.name}</Badge>
                </div>
                <div className="timeline-body">
                  {ReactHtmlParser(sanitizeHtml(reply.body, {
                    allowedTags: [ 'b', 'i', 'em', 'u', 'strong', 'del', 'a', 'br', 'p', 'ul', 'ol', 'li', 'span' ],
                    allowedAttributes: {
                      'a': [ 'href' ]
                    }
                  }))}
                  <hr />
                </div>
                <div className="timeline-footer">
                  <div className="stats pull-left" id={this.props.ticket.id+"_"+reply.date}>
                    <i className="far fa-clock" />
                    <Moment fromNow>{reply.date}</Moment>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target={this.props.ticket.id+"_"+reply.date}
                    placement="bottom"
                  >
                    <Moment format="MMMM Do, YYYY h:mma">{reply.date}</Moment>
                  </UncontrolledTooltip>
                  <div className="attachments pull-right">
                    {this.getAttachments(reply.attachments)}
                  </div>
                </div>
              </div>
            </li>
          );
        },
        this
      );

      return replies;
  }

  render() {
    if (!this.props.showTicketModal) {
      return(<div></div>);
    }
    return (
      <Modal
        isOpen={this.props.showTicketModal}
        toggle={this.closeTicketModal}
        className="modal-long"
        modalClassName="modal-black modal-ticket"
        size="lg"
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.closeTicketModal}
          >
            <i className="fas fa-times" />
          </button>
          <h6 className="title title-up">{this.props.ticket.title}</h6>
        </div>
        <ModalBody className="">
          <Card className="ticket-info">
            <CardBody>
              <Row>
                <Col lg="6">
                  <p>
                    <i className="fas fa-tasks" />Status:
                    <Badge color={this.props.ticketGroups.find((grp) => {return grp.id === this.props.ticket.status}).style} pill>{this.props.ticket.status}</Badge>
                  </p>
                  <p>
                    <i className="fas fa-ticket-alt" />Ticket ID:
                    <code>{this.props.ticket.ticketID}</code>
                  </p>
                  <p>
                    <i className="far fa-envelope-open" />Ticket Subject:
                    <code>{this.props.ticket.title}</code>
                  </p>
                </Col>
                <Col lg="6">
                  <p>
                    <i className="fas fa-clock" />Created On:
                    <code><Moment format="MMMM Do, YYYY hh:mma">{this.props.ticket.timestamp_received}</Moment></code>
                  </p>
                  <p>
                    <i className="fas fa-user" />Created By:
                    <code>{this.props.ticket.from.name} ({this.props.ticket.from.address})</code>
                  </p>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <UncontrolledDropdown className="pull-right">
                    <DropdownToggle caret data-toggle="dropdown" color="info">
                      Move Ticket
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-black">
                      {
                        this.props.ticketGroups.map((ticketGroup) => {
                          if(this.props.ticket.status !== ticketGroup.id) {
                            return (
                              <DropdownItem className="text-uppercase nav-item" key={ticketGroup.id}><i className={ticketGroup.icon} />{ticketGroup.name}</DropdownItem>
                            )
                          }
                          return "";
                        })
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </CardFooter>
          </Card>
          <Card className="card-timeline card-plain">
            <CardBody>
              <ul className="timeline timeline-simple">
                <li className="timeline-inverted">
                  <div className="timeline-badge info">
                    <div className="ticket-photo">
                      <img alt="..." src={require("assets/img/avatar.png")} />
                    </div>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <Badge color="danger">{this.props.userData.full_name}</Badge>
                    </div>
                    <h3 className="card-title">Add Reply</h3>
                    <div className="timeline-body">
                      <Editor
                      editorState={this.state.editorState}
                      toolbarClassName="ticket-reply-toolbar"
                      wrapperClassName="ticket-reply-wrapper"
                      editorClassName="ticket-reply-editor"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        options: ['inline', 'list', 'link', 'image',],
                        inline: {
                          inDropdown: false,
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                          bold: { icon: null, className: 'fas fa-bold' },
                          italic: { icon: null, className: 'fas fa-italic' },
                          underline: { icon: null, className: 'fas fa-underline' },
                          strikethrough: { icon: null, className: 'fas fa-strikethrough' },
                        },
                        list: {
                          inDropdown: false,
                          options: ['unordered', 'ordered'],
                          unordered: { icon: null, className: 'fas fa-list-ul' },
                          ordered: { icon: null, className: 'fas fa-list-ol' },
                        },
                        link: {
                          inDropdown: false,
                          link: { icon: null, className: 'fas fa-link' },
                          unlink: { icon: null, className: 'fas fa-unlink' },
                        },
                        image: {
                          uploadCallback: this.uploadImageCallBack, alt: { present: true, mandatory: true },
                          icon: null,
                          className: 'fa fa-image'
                        },
                      }}
                      />
                      <hr />
                    </div>
                    <div className="timeline-footer">
                      <Button
                        color="info"
                        type="button"
                        onClick={this.handleReplyClick}
                        className="pull-right"
                      >
                        Send Reply
                      </Button>
                    </div>
                  </div>
                </li>
                {this.createReplies()}
              </ul>
            </CardBody>
          </Card>
        </ModalBody>
        <div className="modal-footer">
        </div>
      </Modal>
    );
  }
}

export default TicketModal;
