import React from "react";
import { Auth } from "aws-amplify";
import SweetAlert from 'react-bootstrap-sweetalert';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Col,
  Progress
} from "reactstrap";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      registerEmail: "",
      registerPassword: "",
      registerConfirmPassword: "",
      registerEmailState: "",
      registerPasswordState: "",
      registerConfirmPasswordState: "",
      registerAcceptTermsState: "",
      alert: null,
      passwordStrengthValue: 0,
      passwordStrength: {
        "0": {"text": "strength", "class": "default"},
        "20": {"text": "very weak", "class": "danger"},
        "40": {"text": "weak", "class": "warning"},
        "60": {"text": "good", "class": "info"},
        "80": {"text": "strong", "class": "success"},
        "100": {"text": "very strong", "class": "primary"}
      }
    };
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  successAlert = () => {
    this.setState({
        alert: (
            <SweetAlert
                success
                style={{display: "block"}}
                title="Success!"
                onConfirm={() => this.props.history.push("/auth/verify")}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText="Verify Email"
            >
                You have registered successfully! Check your email for a verification code and enter it on the next page.
            </SweetAlert>
        )
    });
  }

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // determine password password strength
  passwordStrengthCalc = (value) => {
    var strength = 0;
    // check for upper and lowercase
    var reg1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/;
    // check for number
    var reg2 = /^(?=.*\d)/;
    // check for special character
    var reg3 = /^(?=.*[\W])/;

    if (value.length >= 8) {
      strength += 20;
    }
    if (value.length >= 12) {
      strength += 20;
    }
    if (reg1.test(value)) {
      strength += 20;
    }
    if (reg2.test(value)) {
      strength += 20;
    }
    if (reg3.test(value)) {
      strength += 20;
    }

    this.setState({ "passwordStrengthValue": strength > 100 ? 100 : strength });
  }
  // function that verifies if two strings are equal
  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  // convert email to username
  toUsername(email) {
    return email.replace('@', '-at-');
  }
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 8)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        this.passwordStrengthCalc(event.target.value);
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  registerClick = () => {
    var registerFormValid = true;
    if (this.state.registerEmailState === "") {
      registerFormValid = false;
      this.setState({ registerEmailState: "has-danger" });
    }
    if (this.state.registerPasswordState === "") {
      registerFormValid = false;
      this.setState({ registerPasswordState: "has-danger" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      registerFormValid = false;
      this.setState({ registerConfirmPasswordState: "has-danger" });
    }
    if (this.state.registerAcceptTermsState === "") {
      registerFormValid = false;
      this.setState({ registerAcceptTermsState: "has-danger" });
    }

    return registerFormValid;
  };

  handleSubmit = async event => {
    event.preventDefault();

    if(!this.registerClick()) {
      return false;
    }

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.toUsername(this.state.registerEmail),
        password: this.state.registerPassword,
        attributes: {
          email: this.state.registerEmail
        }
      });
      this.setState({
        newUser
      });

      // show success message
      this.successAlert();
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  render() {
    // taking all the states
    let {
      // register form
      registerEmailState,
      registerPasswordState,
      registerConfirmPasswordState,
      registerAcceptTermsState
    } = this.state;
    return (
      <>
        <div className="content">
          <Col md="4" className="offset-md-4">
            <Form id="RegisterUser" onSubmit={this.handleSubmit}>
              <Card className="card-register">
                <CardHeader>
                  <CardTitle tag="h4">Sign Up</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className={`has-label ${registerEmailState}`}>
                    <label>Email Address *</label>
                    <Input
                      name="email"
                      type="email"
                      autoComplete="off"
                      onChange={e => this.change(e, "registerEmail", "email")}
                    />
                    {this.state.registerEmailState === "has-danger" ? (
                      <label className="error">Please enter a valid email address.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${registerPasswordState}`}>
                    <label>Password *</label>
                    <Input
                      id="registerPassword"
                      name="password"
                      type="password"
                      autoComplete="off"
                      onChange={e =>
                        this.change(e, "registerPassword", "password")
                      }
                    />
                    <div className={"progress-container progress-"+this.state.passwordStrength[this.state.passwordStrengthValue].class}>
                      <span className="progress-badge card-category">{this.state.passwordStrength[this.state.passwordStrengthValue].text}</span>
                      <Progress max="100" value={this.state.passwordStrengthValue} barClassName="progress-bar-primary">
                      </Progress>
                    </div>
                    {this.state.registerPasswordState === "has-danger" ? (
                      <label className="error">Password must be at least 8 characters.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup
                    className={`has-label ${registerConfirmPasswordState}`}
                  >
                    <label>Confirm Password *</label>
                    <Input
                      equalto="#registerPassword"
                      id="registerPasswordConfirmation"
                      name="password_confirmation"
                      type="password"
                      autoComplete="off"
                      onChange={e =>
                        this.change(
                          e,
                          "registerConfirmPassword",
                          "equalTo",
                          "registerPassword"
                        )
                      }
                    />
                    {this.state.registerConfirmPasswordState ===
                    "has-danger" ? (
                      <label className="error">The passwords entered are not the same.</label>
                    ) : null}
                  </FormGroup>
                  <div className="category form-category">
                    * Required fields
                  </div>
                </CardBody>
                <CardFooter className="text-right">
                  <FormGroup check
                    className={`form-group pull-left has-label ${registerAcceptTermsState}`}>
                    <Label check>
                      <Input
                        name="optionCheckboxes"
                        id="registerAcceptTerms"
                        type="checkbox"
                        onChange={e =>
                          this.change(e, "registerAcceptTerms", "checkbox")
                        }
                      />
                      <span className="form-check-sign" />
                      Accept the terms and conditions
                    </Label>
                  </FormGroup>
                  <Button color="primary" type="submit">
                    Sign Up
                  </Button>
                </CardFooter>
              </Card>
            </Form>
            {this.state.alert}
          </Col>
        </div>
      </>
    );
  }
}

export default Register;
