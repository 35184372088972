/*eslint-disable*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Splash extends React.Component {
  render() {
    return (
      <div className="content splash">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h1 className="title">etiqit</h1>
              <h4 className="subtitle">
                Helpdesk made simple.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Splash;
