import React from "react";
import { Auth } from "aws-amplify";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // login form
      loginEmail: "",
      loginPassword: "",
      loginEmailState: "",
      loginPasswordState: "",
      validated: false,
      isAuthenticated: false,
      isLoading: false
    };
  }
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    var checkValid = true;
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          checkValid = false;
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          checkValid = false;
        }
        break;
      default:
        break;
    }
    this.setState({ validated: checkValid });
    this.setState({ [stateName]: event.target.value });
  };
  loginClick = () => {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "has-danger" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "has-danger" });
    }
  };
  validateForm = () => {
    return this.state.validated;
  }
  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.loginEmail, this.state.loginPassword);
      this.userHasAuthenticated(true);
      this.props.history.push("/app/home");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };
  render() {
    let {
      // login form
      loginEmailState,
      loginPasswordState
    } = this.state;
    return (
      <>
        <div className="content">
          <Col md="6" lg="4" className="offset-md-3 offset-lg-4">
            <Form id="LoginValidation" onSubmit={this.handleSubmit}>
              <Card className="card-login">
                <CardHeader>
                  <CardTitle tag="h4">Login</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className={`has-label ${loginEmailState}`}>
                    <label>Email Address *</label>
                    <Input
                      name="email"
                      type="email"
                      onChange={e => this.change(e, "loginEmail", "email")}
                    />
                    {this.state.loginEmailState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${loginPasswordState}`}>
                    <label>Password *</label>
                    <Input
                      name="password"
                      type="password"
                      autoComplete="off"
                      onChange={e =>
                        this.change(e, "loginPassword", "password")
                      }
                    />
                    {this.state.loginPasswordState === "has-danger" ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <div className="category form-category">
                    * Required fields
                  </div>
                </CardBody>
                <CardFooter className="login-footer">
                  <Button color="primary" type="submit" disabled={!this.validateForm()}>
                    Login
                  </Button>
                  <a
                    href="/auth/forgotpassword"
                    onClick={e => e.preventDefault}
                  >
                    Forgot password?
                  </a>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </div>
      </>
    );
  }
}

export default Login;
