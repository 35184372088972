import React from "react";
import { Auth } from "aws-amplify";
import SweetAlert from 'react-bootstrap-sweetalert';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";

class VerifySignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // verify form
      verifyEmail: "",
      verifyCode: "",
      verifyEmailState: "",
      verifyCodeState: "",
      validated: false,
      isLoading: false,
      alert: null
    };
  }

  hideAlert = () => {
    this.setState({ alert: null });
  }

  successAlert = () => {
    this.setState({
        alert: (
            <SweetAlert
                success
                style={{display: "block"}}
                title="Success!"
                onConfirm={() => this.props.history.push("/auth/login")}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText="Login"
            >
                Your email has been verified! Login to start using Etiqit!
            </SweetAlert>
        )
    });
  }

  resendSuccessAlert = () => {
    this.setState({
        alert: (
            <SweetAlert
                success
                style={{display: "block"}}
                title="Success!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info"
                confirmBtnText="Enter new code"
            >
                A new verification code has been sent to your email.
            </SweetAlert>
        )
    });
  }

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has exactly a given length or not
  verifyLengthExact = (value, length) => {
    if (value.length === length) {
      return true;
    }
    return false;
  };
  // convert email to username
  toUsername(email) {
    return email.replace('@', '-at-');
  }
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    var checkValid = true;
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "code":
        if (this.verifyLengthExact(event.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          checkValid = false;
        }
        break;
      default:
        break;
    }
    this.setState({ validated: checkValid });
    this.setState({ [stateName]: event.target.value });
  };
  verifyClick = () => {
    var verifyFormValid = true;
    if (this.state.verifyEmailState === "") {
      verifyFormValid = false;
      this.setState({ verifyEmailState: "has-danger" });
    }
    if (this.state.verifyCodeState === "") {
      verifyFormValid = false;
      this.setState({ verifyCodeState: "has-danger" });
    }

    return verifyFormValid;
  };
  validateForm = () => {
    return this.state.validated;
  }

  handleSubmit = async event => {
    event.preventDefault();

    if(!this.verifyClick()) {
      return false;
    }

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.toUsername(this.state.verifyEmail), this.state.verifyCode);

      // show success message
      this.successAlert();
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  resendCode = async event => {
    event.preventDefault();

    if(this.state.verifyEmailState !== "has-success") {
      console.log(this.state.verifyEmailState);
      this.setState({ verifyEmailState: "has-danger" });
      return false;
    }

    this.setState({ isLoading: true });

    try {
      await Auth.resendSignUp(this.toUsername(this.state.verifyEmail));

      // show success message
      this.resendSuccessAlert();
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  render() {
    let {
      // verify form
      verifyEmailState,
      verifyCodeState
    } = this.state;
    return (
      <>
        <div className="content">
          <Col md="6" lg="4" className="offset-md-3 offset-lg-4">
            <Form id="VerifySignup" onSubmit={this.handleSubmit}>
              <Card className="card-login">
                <CardHeader>
                  <CardTitle tag="h4">Verify Email</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className={`has-label ${verifyEmailState}`}>
                    <label>Email *</label>
                    <Input
                      name="email"
                      type="email"
                      onChange={e => this.change(e, "verifyEmail", "email")}
                    />
                    {this.state.verifyEmailState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${verifyCodeState}`}>
                    <label>Verification Code *</label>
                    <Input
                      name="code"
                      type="code"
                      autoComplete="off"
                      onChange={e =>
                        this.change(e, "verifyCode", "code")
                      }
                    />
                    {this.state.verifyCodeState === "has-danger" ? (
                      <label className="error">Verification code must be 6 digits.</label>
                    ) : null}
                  </FormGroup>
                  <div className="category form-category">
                    * Required fields
                  </div>
                </CardBody>
                <CardFooter className="login-footer">
                  <Button color="primary" type="submit" disabled={!this.validateForm()}>
                    Verify
                  </Button>
                  <a
                    href="#resend"
                    onClick={this.resendCode}
                  >
                    Resend Verification Code
                  </a>
                </CardFooter>
              </Card>
            </Form>
            {this.state.alert}
          </Col>
        </div>
      </>
    );
  }
}

export default VerifySignup;
