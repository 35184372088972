import React from "react";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
//import Footer from "components/Footer/Footer.jsx";
import Splash from "components/Site/Splash.jsx";

//import routes from "routes.js";

class Site extends React.Component {
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }
  render() {
    return (
      <>
        <AuthNavbar brandText="&nbsp;" />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <Splash />
        </div>
      </>
    );
  }
}

export default Site;
